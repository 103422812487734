@font-face {
  font-family: 'Whyte_Black';
  src: url('fonts/Whyte/ABCWhyte-Black.woff2') format('woff2'),
    url('fonts/Whyte/ABCWhyte-Black.woff') format('woff');
}

@font-face {
  font-family: 'Whyte_Bold';
  src: url('fonts/Whyte/ABCWhyte-Bold.woff2') format('woff2'),
    url('fonts/Whyte/ABCWhyte-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Whyte_Regular';
  src: url('fonts/Whyte/ABCWhyte-Regular.woff2') format('woff2'),
    url('fonts/Whyte/ABCWhyte-Regular.woff') format('woff');
}

@font-face {
  font-family: 'WhyteInktrap_Black';
  src: url('fonts/Whyte Inktrap/ABCWhyteInktrap-Black.woff2') format('woff2'),
    url('fonts/Whyte Inktrap/ABCWhyteInktrap-Black.woff') format('woff');
}
