:root {
  /* fonts */
  --fn0b: Whyte_Bold;
  --fn0r: Whyte_Regular;
  --fn0h: Whyte_Black;
  --fn1h: WhyteInktrap_Black;

  /* colors */
  --c1: #ffffff;
  --c2: #191919;
  --c3: #007bfe;
  --c4: #5b5b5b;
  --c5: #c0c0c0;
  --c6: #f5f5f5;
  --c7: #434343;
  --c8: #134996;
  --c9: #e0e0e0;
  --c10: #616161;
  --c11: #ffde3d;
  --c12: #010101;

  /* size */
  --s0: 0px;
  --s1: 4px;
  --s2: 8px;
  --s3: 12px;
  --s4: 16px;
  --s5: 20px;
  --s6: 24px;
  --s7: 28px;
  --s8: 32px;
  --s9: 36px;
  --s10: 40px;
}

/* generic styles */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--c1);
}

h1,
h2,
h3,
p {
  margin: 0;
}

img {
  width: 100%;
}

main {
  background-color: var(--c3);
}

button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--s1) var(--s2);
  border-radius: var(--s1);
  font-family: var(--fn0r);
}

.join-form-header {
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.scroller-text {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  list-style-type: none;
  font-family: var(--fn0r);
  animation: scroller-text-animation 20s linear infinite;
  padding: 0;
}

@keyframes scroller-text-animation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.scroller-brands {
  --gap: 20px;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  grid-column: 1/12;
}

.animation-items-container {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  list-style-type: none;
  font-family: var(--fn0r);
  animation: scroller-text-animation 15s linear infinite;
  padding: 0;
  margin: 0;
}

#brands img {
  height: 70px;
}

/* Make it move */
@keyframes scroller-brands-animation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.header-highlight {
  background-color: var(--c3);
  color: var(--c1);
  padding: var(--s0) var(--s3);
}

.azure-bg {
  background-color: var(--c3);
  position: relative;
}

.white-bg {
  background-color: var(--c1);
  position: relative;
}

.min-padding {
  padding: 1% 1% 0;
}

.blue-gradient-bg {
  background: radial-gradient(
      98.44% 227.32% at 1.56% 8.56%,
      var(--c3) 0%,
      var(--c8) 100%
    )
    var(--c1);
}

.black-bg {
  background-color: var(--c12);
}

.cultured-bg {
  background-color: var(--c6);
}

.small {
  font-size: 12px;
  color: var(--c10);
  font-family: var(--fn0r);
}

div.nav-container {
  position: sticky;
  top: 4px;
  display: flex;
  justify-content: center;
  z-index: 5;
}

/* navbar */
nav {
  box-shadow: 0 var(--s2) var(--s6) rgba(96, 96, 96, 0.04);
  padding: var(--s2) var(--s4);
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  border-radius: var(--s8);
  border: 1px solid var(--c2);
  margin: 0 var(--s1);
  background-color: var(--c1);
  align-items: center;
  /*position: sticky;
  top: 4px;
  z-index: 5;*/
  width: 100%;
}

nav button.ham-menu {
  background-color: unset;
  border: unset;
  display: block;
}

nav a {
  grid-column: 1/5;
  grid-row: 1/2;
  text-decoration: unset;
  display: contents;
}

nav a img {
  width: 150px;
}

nav button {
  grid-column: 10/11;
  grid-row: 1/2;
}

nav ul.none {
  display: none;
}

nav ul {
  grid-column: 2/9;
  grid-row: 3/4;
  list-style-type: none;
  display: flex;
  flex-flow: wrap row;
  gap: var(--s2);
}

nav ul.mobile-list {
  display: flex;
  grid-column: 1/3;
  flex-flow: wrap column;
  padding: 0;
}

nav ul.desktop-list {
  display: none;
}

/* pages */
/* home */
#intro {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  padding: var(--s4);
}

#intro h2 {
  grid-column: 1/11;
  grid-row: 1/3;
  font-size: 44px;
  font-family: var(--fn1h);
  line-height: 120%;
  letter-spacing: -0.01em;
  color: var(--c2);
  text-align: center;
}

#intro h3 {
  grid-column: 2/10;
  grid-row: 5/7;
  color: var(--c1);
  line-height: 100%;
  font-family: var(--fn0r);
  font-size: var(--s4);
  text-align: center;
  margin: 20px 20px 20px 0;
  padding-bottom: 40px;
}

#intro .form-container {
  grid-column: 1/12;
  grid-row: 9/13;
}

#intro iframe {
  border-radius: 10px;
  grid-column: 2/10;
  grid-row: 16/18;
  width: 100%;
  height: 100%;
  border: 0 solid black;
}

#intro p {
  color: var(--c4);
  font-family: var(--fn0r);
  text-align: center;
}

#join {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
}

#join div.join-form-header {
  grid-column: 1/12;
  border-top: 1px solid var(--c2);
  border-bottom: 1px solid var(--c2);
}

#join div.animation-container {
  display: flex;
  gap: var(--s4);
  width: 400%;
}

#join h4.style1 {
  text-align: start;
  font-size: var(--s7);
  margin: 0;
}

#join div.form-container {
  grid-column: 1/12;
  padding: 0 var(--s4) var(--s4) var(--s4);
}

#join div.form-content {
  flex-direction: column;
  gap: 2px;
  margin: 20px 0 10px 0;
}

#join input {
  font-size: 64px;
  width: 100%;
  line-height: 65px;
}

#join div.form-btn-container {
  display: flex;
  justify-content: center;
}

#links {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  background-color: var(--c3);
}

#links img.mobile-jagged-left {
  grid-column: 1/3;
  grid-row: 2/6;
  display: block;
}

#links img.mobile-jagged-right {
  grid-column: 9/11;
  grid-row: 2/6;
  display: block;
}

#links h2 {
  color: var(--c2);
  font-family: var(--fn1h);
  grid-column: 4/8;
  grid-row: 1/4;
  text-align: center;
  font-size: var(--s6);
  margin-top: var(--s5);
}

#links p {
  color: var(--c1);
  font-family: var(--fn0r);
  grid-column: 3/9;
  grid-row: 3/6;
  text-align: center;
  font-size: var(--s4);
}

#links p.qr-code {
  display: none;
}

#links p.download {
  display: block;
}

#links img.qr-code {
  display: none;
}

img.play-store-mobile {
  display: initial;
}

img.play-store-desktop {
  display: none;
}

#links .app-links {
  display: flex;
  flex-direction: row;
  gap: var(--s3);
  padding: var(--s2);
  grid-column: 3/9;
  grid-row: 5/7;
}

#earn-redeem {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  background-color: var(--c1);
  padding: 1%;
}

#earn-redeem div.slide-container {
  grid-column: 1/12;
  --notchSize: var(--s8);
  display: flex;
  clip-path: polygon(
    0% var(--notchSize),
    var(--notchSize) 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - var(--notchSize)),
    calc(100% - var(--notchSize)) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );
  background-color: var(--c3);
  margin: var(--s7);
  gap: 1px;
  padding: var(--s3);
}

div.slide-container div.index-btns {
  display: flex;
  flex-direction: column;
  gap: var(--s3);
  align-self: center;
}

div.index-btns button.slide-mobile-btn {
  background-color: var(--c2);
  border-radius: 8px;
  border: unset;
  padding: 6px;
}

div.index-btns button.slide-btn {
  display: none;
}

#earn-redeem div.slide-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 2/12;
  grid-row: 1/6;
  justify-content: space-between;
  padding: 8%;
}

div.slide-content-container h3 {
  color: var(--c1);
  border: 1px solid var(--c1);
  border-radius: 40px;
  font-size: 0.8rem;
  padding: 0 var(--s5);
  font-family: var(--fn0b);
  align-self: flex-start;
}

div.slide-content {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  margin-top: 10px;
}

div.slide-content-text {
  color: var(--c1);
  font-size: var(--s4);
  font-family: var(--fn0r);
  margin: var(--s3);
  text-align: start;
  height: 105px;
}

#brands {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  border-top: 1px solid var(--c2);
  border-bottom: 1px solid var(--c2);
  padding: 20px 0;
}

#brands h2 {
  grid-column: 1/12;
  grid-row: 1/3;
  color: var(--c2);
  font-size: 28px;
  font-family: var(--fn1h);
  text-align: start;
  padding: 0 10px;
}

#brands > div.brands {
  grid-column: 1/12;
  grid-row: 4/5;
}

#signup {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  padding: 40px 0;
}

#signup h2 {
  grid-column: 1/12;
  grid-row: 1/2;
  font-size: var(--s7);
  line-height: 120%;
  color: var(--c3);
  margin: var(--s2);
  text-align: center;
  font-family: var(--fn0h);
}

#signup h3 {
  margin: var(--s4) 0 var(--s4) var(--s1);
}

#signup ol {
  padding: var(--s4);
  grid-column: 1/12;
  grid-row: 4/6;
  font-size: var(--s4);
  font-family: var(--fn0r);
  list-style: none;
  counter-reset: custom-counter;
}

#signup ol li {
  display: flex;
  align-items: baseline;
  counter-increment: custom-counter;
}

#signup ol li::before {
  content: counter(custom-counter) '.';
  color: var(--c2);
  font-family: var(--fn0r);
  font-size: var(--s5);
}

#signup .earn-phones {
  display: none;
}

#signup .form-container {
  grid-column: 1/12;
  grid-row: 13/15;
  margin: var(--s4);
}

#signup div.form-content {
  flex-direction: column;
}

#signup input {
  font-size: 34px;
  width: 75%;
  line-height: 35px;
}

#signup form.waitlist {
  display: flex;
  align-content: space-between;
  align-items: baseline;
  margin-top: var(--s3);
}

#signup p.small {
  font-size: 10px;
}

#about_us {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  padding: var(--s2) 0;
}

#about_us h2 {
  grid-column: 1/12;
  grid-row: 1/5;
  font-size: var(--s9);
  line-height: 120%;
  letter-spacing: -0.01em;
  color: var(--c2);
  font-family: var(--fn0h);
  text-align: start;
  margin: var(--s4);
}

#about_us button {
  grid-column: 4/8;
  grid-row: 6/7;
  background: var(--c11);
  color: var(--c2);
  font-size: var(--s4);
  border-radius: var(--s10);
  border: none;
  padding: var(--s2) var(--s3);
}

#about_us img {
  grid-column: 1/12;
  grid-row: 10/10;
}

/* community */
#community {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
}

#community article {
  color: var(--c4);
  font-family: var(--fn0r);
  grid-column: 1/12;
  grid-row: 3/12;
  text-align: start;
  padding: 24px 24px 32px 24px;
}

#community h1 {
  font-family: var(--fn1h);
  font-size: var(--s8);
  line-height: 120%;
  text-align: start;
  color: var(--c2);
}

#community h2 {
  font-family: var(--fn1h);
  font-size: 24px;
  line-height: 120%;
  text-align: start;
  color: var(--c2);
}

#community p {
  padding: var(--s6) 0;
  color: var(--c1);
  font-size: var(--s4);
}

#community div.community-animation-header {
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  grid-column: 1/12;
  grid-row: 12/13;
  border-top: 1px solid var(--c2);
  border-bottom: 1px solid var(--c2);
  background-color: var(--c1);
}

#community ul.scroller-text {
  --gap: 1rem;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  list-style-type: none;
  font-family: var(--fn0r);
  animation: community-scroller-text-animation 20s linear infinite;
  padding: 0;
  font-size: var(--s4);
}

@keyframes community-scroller-text-animation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

#community img {
  display: none;
}

#community-cta {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  padding: 30px 0;
}

#community-cta .form-container {
  grid-column: 1/12;
  grid-row: 1/3;
  margin: var(--s2);
  text-align: center;
}

#community-litepaper {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  padding: 30px 0;
}

#community-litepaper h2 {
  grid-column: 2/10;
  grid-row: 4/5;
  font-family: var(--fn1h);
  color: var(--c3);
  text-align: center;
}

#community-litepaper a:link {
  color: var(--c3);
}

#community-litepaper a:visited {
  color: var(--c3);
}

/* privacy policy */
#privacy-policy {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
}

#privacy-policy h1 {
  font-family: var(--fn1h);
  font-size: 44px;
  line-height: 120%;
  grid-column: 1/12;
  text-align: center;
  grid-row: 1/2;
}

#privacy-policy .article1 {
  grid-column: 2/9;
  grid-row: 3/6;
  margin: var(--s6) 0;
}

#privacy-policy .article2 {
  grid-column: 2/9;
  grid-row: 7/11;
  margin: var(--s6) 0;
}

#privacy-policy .article3 {
  grid-column: 2/9;
  grid-row: 12/17;
  margin: var(--s6) 0;
}

#privacy-policy .article4 {
  grid-column: 2/9;
  grid-row: 18/21;
  margin: var(--s6) 0;
}

#privacy-policy .article5 {
  grid-column: 2/9;
  grid-row: 22/25;
  margin: var(--s6) 0;
}

#privacy-policy h2 {
  grid-column: 4/5;
  font-family: var(--fn0h);
  font-size: 36px;
  margin: var(--s3) 0;
}

#privacy-policy h3 {
  grid-column: 4/5;
  font-family: var(--fn0b);
  font-size: 22px;
  margin-top: 2%;
}

#privacy-policy h4 {
  grid-column: 4/5;
  font-family: var(--fn0b);
  font-size: var(--s5);
  margin: 0;
}

#privacy-policy h5 {
  grid-column: 4/5;
  font-family: var(--fn0b);
  font-size: var(--s4);
  margin: 0;
}

#privacy-policy table {
  display: none;
}

#privacy-policy p {
  color: var(--c4);
  font-family: var(--fn0r);
  grid-column: 2/8;
  grid-row: 4/4;
  padding: var(--s2) 0;
}

#privacy-policy a:link {
  color: var(--c4);
}

#privacy-policy a:visited {
  color: var(--c4);
}

#privacy-policy p.direct {
  background-color: #a27e7a;
  border-radius: var(--s1);
  color: var(--c1);
  text-align: center;
  margin: var(--s1) 0;
}

#privacy-policy p.nordigen {
  background-color: #844c1d;
  border-radius: var(--s1);
  color: var(--c1);
  text-align: center;
  margin: var(--s1) 0;
}

#privacy-policy p.automatic {
  background-color: #603b2c;
  border-radius: var(--s1);
  color: var(--c1);
  text-align: center;
  margin: var(--s1) 0;
}

/* faq */
#faq {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  gap: var(--s4);
  background-color: var(--c6);
  padding: 30px 0;
}

#faq h1 {
  font-family: var(--fn0h);
  font-size: var(--s8);
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--c2);
  grid-column: 1/12;
  grid-row: 1/2;
}

#faq h3 {
  font-family: var(--fn0h);
  font-size: 22px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--c2);
  grid-column: 1/12;
  grid-row: 1/2;
}

#faq .questions {
  grid-column: 3/9;
  grid-row: 2/6;
}

#faq .more-questions {
  grid-column: 1/12;
  grid-row: 7/9;
  text-align: center;
}

#faq .more-questions h4 {
  font-size: 14px;
  line-height: 140%;
  color: var(--c10);
  font-family: var(--fn0r);
  margin-top: 0;
}

#faq a:link {
  color: var(--c10);
}

/* components */
/* button */
button.light {
  background-color: var(--c1);
  color: var(--c2);
  padding: var(--s2);
  font-size: var(--s5);
  border: unset;
}

button.app-button {
  background-color: var(--c2);
  color: var(--c1);
  font-size: var(--s5);
  border: unset;
  border-radius: 20px;
  padding: 0 14px;
}

button.app-button:hover,
button.app-button:active,
button.app-button:focus {
  color: var(--c1);
  font-family: var(--fn0b);
  background-color: var(--c2);
}

button.light:hover,
button.light:active,
button.light:focus {
  color: var(--c1);
  font-family: var(--fn0b);
  background-color: var(--c2);
  border-radius: 20px;
  padding: 0 12px;
}

button.mobile-btn {
  background: var(--c11);
  color: var(--c2);
  font-size: var(--s6);
  border-radius: var(--s10);
  border: none;
  padding: 0 var(--s4);
}

button.desktop-btn {
  display: none;
}

/* footer */
footer {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  box-shadow: 0 0 var(--s6) rgba(96, 96, 96, 0.04);
  padding: 20px var(--s4);
}

footer div.top {
  grid-column: 1/12;
  grid-row: 1/3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--c2);
  padding-bottom: var(--s8);
  flex-direction: column;
}

div.top #logo {
  width: 55%;
  margin-bottom: var(--s3);
}

footer .contact {
  display: flex;
  gap: var(--s5);
  width: 10%;
  min-width: 232px;
}

footer div.down {
  grid-column: 1/12;
  grid-row: 4/6;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--s1) 0;
}

div.down p {
  color: var(--c2);
  font-size: var(--s5);
  font-family: var(--fn0r);
}

footer ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

footer button.light {
  padding: 0;
  font-size: var(--s5);
}

/* form */
div.form-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 var(--s2) var(--s6) rgba(96, 96, 96, 0.04);
  border-radius: var(--s3);
}

div.form-container2 {
  border: 1px solid var(--c9);
  background-color: var(--c1);
  padding: var(--s1) var(--s2);
}

div.form-container1 > p.small {
  text-align: start;
  padding: 0 var(--s10);
  font-size: var(--s2);
}

div.form-container h4 {
  font-family: var(--fn0b);
  color: var(--c3);
  text-align: center;
  font-size: 120%;
  margin: 1%;
}

div.form-container h4.style1 {
  font-size: var(--s7);
  text-align: start;
}

div.form-container h4.style2 {
  color: var(--c2);
}

div.form-container1 input {
  font-family: var(--fn1h);
  color: var(--c2);
  border: none;
  border-bottom: 1px solid var(--c2);
  padding: 0;
}

div.form-container2 input {
  width: 70%;
  padding: var(--s1);
}

div.form-container2 form {
  display: flex;
  flex-direction: row;
  gap: var(--s2);
  justify-content: center;
  margin: var(--s2);
}

form div.form-content {
  display: flex;
  justify-content: space-between;
}

input.form-content-input:focus {
  outline: none;
}

/* explainer */
button.explainer-container {
  background-color: var(--c1);
  padding: var(--s3) var(--s4);
  margin: var(--s3) var(--s4);
  box-shadow: 0 2px var(--s1) rgba(0, 0, 0, 0.08);
  border-radius: var(--s1);
  border-color: unset;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

div.explainer-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

h4.explainer-question {
  margin: auto var(--s4);
  font-family: var(--fn0r);
  color: var(--c2);
  font-size: var(--s4);
}

p.explainer-answer {
  text-align: start;
  padding: var(--s3);
  font-family: var(--fn0r);
  font-size: 14px;
  line-height: 140%;
  color: var(--c10);
}

@media (min-width: 744px) {
  nav {
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  nav a {
    text-decoration: unset;
  }

  nav a img {
    margin-top: 3px;
    width: 180px;
  }

  nav button.ham-menu {
    display: none;
  }

  nav ul.desktop-list {
    display: flex;
    margin: 0;
    align-items: center;
    grid-column: 6/12;
    grid-row: 1/2;
    padding: 0;
  }

  #intro h2 {
    text-transform: uppercase;
  }

  #intro .form-container {
    order: 2;
    grid-column: 3/9;
  }

  #intro iframe {
    grid-column: 4/8;
  }

  #join h4.style1 {
    font-size: 44px;
    text-align: center;
  }

  #links {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: auto;
    padding: var(--s4);
    gap: var(--s2);
  }

  #links img.qr-code {
    grid-column: 7/9;
    grid-row: 1/6;
    display: block;
    width: 110%;
  }

  img.play-store-mobile {
    display: none;
  }

  img.play-store-desktop {
    display: block;
  }

  #links h2 {
    font-family: var(--fn1h);
  }

  #links p.qr-code {
    display: block;
  }

  #links p.download {
    display: none;
  }

  #join div.form-container {
    padding: var(--s9) var(--s9) 0 var(--s9);
  }

  #join form.waitlist {
    display: flex;
    gap: var(--s4);
    align-items: baseline;
    justify-content: center;
  }

  #join div.form-btn-container {
    display: block;
  }

  .scroller-text {
    font-size: 24px;
  }

  .scroller-text span {
    padding-left: 100%;
  }

  #links img.mobile-jagged-left {
    display: none;
  }

  #links img.mobile-jagged-right {
    display: none;
  }

  #links {
    background: url('images/jagged-blue.svg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #links h2 {
    grid-column: 3/6;
    grid-row: 4/5;
    font-size: var(--s5);
    text-align: start;
  }

  #links img.qr-code {
    grid-column: 6/7;
    grid-row: 1/10;
  }

  #links .app-links {
    grid-column: 7/9;
    grid-row: 1/10;
    display: flex;
    flex-direction: column;
    gap: var(--s1);
    align-items: center;
  }

  #links p {
    grid-column: 3/6;
    grid-row: 5/6;
    font-size: var(--s3);
    text-align: start;
  }

  div.slide-content-container h3 {
    font-size: var(--s8);
    align-self: center;
  }

  div.slide-content {
    align-items: center;
  }

  div.slide-content-text {
    font-size: var(--s7);
    margin: var(--s5);
  }

  div.index-btns button.slide-mobile-btn {
    padding: var(--s4);
    border-radius: var(--s7);
  }

  #earn-redeem img {
    width: 70%;
  }

  #brands h2 {
    grid-column: 1/8;
    font-size: 44px;
  }

  #signup input {
    font-size: 44px;
    line-height: 45px;
  }

  #signup form.waitlist {
    display: flex;
    align-content: space-between;
    align-items: baseline;
    margin-top: var(--s3);
  }

  #signup p.small {
    font-size: var(--s3);
  }

  #signup button.desktop-btn {
    font-size: var(--s4);
  }

  #signup .form-container {
    grid-column: 3/9;
  }

  #signup ol {
    grid-column: 3/9;
  }

  #about_us h2 {
    grid-column: 3/10;
    font-size: 40px;
  }

  #about_us button {
    grid-column: 5/7;
  }

  #community .social-container {
    grid-column: 4/8;
  }

  #community h1 {
    font-size: var(--s10);
  }

  #community h2 {
    font-size: var(--s8);
  }

  #community p {
    font-size: var(--s5);
  }

  #community ul {
    font-size: var(--s5);
  }

  #community .form-container {
    grid-column: 3/9;
  }

  #privacy-policy table {
    color: var(--c4);
    font-family: var(--fn0r);
    border-collapse: collapse;
    display: block;
  }

  #privacy-policy tr {
    border: 1px solid var(--c2);
  }

  #privacy-policy td {
    vertical-align: top;
    border-left: 1px solid var(--c2);
    border-right: 1px solid var(--c2);
    padding: var(--s2);
  }

  #privacy-policy td:first-child {
    border-left: none;
  }

  #privacy-policy td:last-child {
    border-right: none;
  }

  div.form-container2 input {
    width: 80%;
    padding: var(--s3) var(--s4);
  }

  button.mobile-btn {
    display: none;
  }

  button.desktop-btn {
    background: var(--c11);
    color: var(--c2);
    border-radius: var(--s10);
    border: none;
    padding: var(--s3) var(--s9);
    display: block;
    font-size: var(--s6);
    font-family: var(--fn0b);
  }

  button.desktop-btn:hover {
    cursor: pointer;
  }

  footer div.top {
    flex-direction: row;
  }

  div.top #logo {
    width: 25%;
  }

  div.down p {
    font-size: var(--s4);
  }

  footer button.light {
    font-size: var(--s4);
  }
}

@media (min-width: 1080px) {
  #intro {
    margin: 0 auto;
  }

  #intro h2 {
    font-size: 82px;
    grid-column: 1/6;
    order: 2;
    grid-row: 1/4;
    text-align: start;
    width: 85%;
  }

  #intro h3 {
    grid-column: 1/6;
    grid-row: 6/10;
    text-align: start;
    order: 2;
    font-size: 48px;
    padding-bottom: 60px;
  }

  #intro iframe {
    grid-column: 7/12;
    grid-row: 1/13;
  }

  #intro .form-container {
    grid-column: 2/6;
    grid-row: 4/5;
  }

  .scroller-text {
    animation: scroller-text-animation 15s linear infinite;
  }

  #join h4.style1 {
    font-size: 48px;
  }

  #join button.yellow {
    font-size: var(--s10);
    padding: 0 50px;
  }

  #join div.form-container {
    padding: var(--s6) var(--s6) 0 var(--s6);
  }

  #join input {
    font-size: 70px;
    line-height: 71px;
  }

  /*#join form.waitlist {
    display: flex;
    gap: var(--s4);
    align-items: baseline;
    justify-content: center;
  }*/

  #links h2 {
    font-size: var(--s9);
    font-family: var(--fn0b);
  }

  #links p {
    font-size: var(--s6);
  }

  /*img.qr-code {
    width: 110%;
  }*/

  #earn-redeem {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: auto;
    background-color: var(--c1);
    padding: 1%;
  }

  #earn-redeem div.slide-container {
    grid-column: 1/12;
    display: flex;
    background: url('images/box-shape-background.svg') no-repeat;
    background-size: 100% 100%;
    padding: 10% 0 10% 10%;
    clip-path: unset;
    gap: 100px;
    /*display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: auto;

    max-width: 1200px;*/
  }

  div.index-btns {
    display: flex;
    flex-direction: column;
    gap: var(--s3);
    align-self: center;
  }

  div.index-btns button.slide-btn {
    border-radius: 60px;
    width: 64px;
    display: block;
    border-color: var(--c2);
    font-size: 38px;
  }

  div.index-btns button.slide-mobile-btn {
    display: none;
  }

  /*div.index-btns button.slide-btn {
    display: block;
    border-radius: 60px;
    width: 64px;
    border-color: var(--c2);
    font-size: 38px;
  }*/

  div.index-btns button.active {
    background-color: var(--c2);
    color: var(--c1);
  }

  div.index-btns button.inactive {
    background-color: unset;
    color: var(--c2);
  }

  #earn-redeem div.slide-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: 2/12;
    grid-row: 1/6;
    justify-content: space-between;
    padding: 80px 0 80px 80px;
  }

  #earn-redeem div.slide-content-container h3 {
    grid-column: 1/2;
    grid-row: 1/2;
    color: var(--c1);
    border: 1px solid var(--c1);
    border-radius: 40px;
    padding: 0 var(--s5);
    font-family: var(--fn0b);
    align-self: flex-start;
    margin-left: -5vw;
    font-size: 30px;
  }

  #earn-redeem div.slide-content {
    display: flex;
    flex-flow: unset;
    justify-content: space-between;
    margin-top: 50px;
    flex-direction: row;
    align-items: unset;
  }

  #earn-redeem div.slide-content-text {
    min-width: 230px;
    /*max-width: 360px;*/
    color: var(--c1);
    font-family: var(--fn0r);
    margin: var(--s5);
    width: 40vw;
    margin-left: -5vw;
    font-size: 26px;
  }

  #earn-redeem img {
    width: 70%;
  }
  /*div.slide-content {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;
  }*/

  /* #earn-redeem {
     border: 60px solid var(--c1);
   }

   #earn-redeem div.slide-container {
     background-size: 100% 100%;
     padding: 80px 20px;
     gap: 100px;
   }

   #earn-redeem div.slide-content-container {
     padding: 8% 0;
   }



   div.slide-content-container h3 {
     font-size: 30px;
     align-self: flex-start;
   }

   div.slide-content-text {
     margin-top: 50px;
     font-size: var(--s6);
   }

   #earn-redeem img {
     position: relative;
     top: -40px;
     width: 60%;
   }*/

  #brands > div.brands {
    margin-top: 59px;
  }

  #brands div.animation-container {
    gap: var(--s10);
    width: 150%;
  }

  #brands h2 {
    grid-column: 1/7;
    font-size: 64px;
  }

  #brands img {
    height: 200px;
  }

  .scroller-brands {
    --gap: 60px;
  }

  #signup h2 {
    grid-column: 6/10;
    text-align: start;
    font-size: var(--s9);
    margin: 0;
  }

  #signup ol {
    grid-column: 6/10;
    grid-row: 2/12;
    font-size: var(--s5);
  }

  #signup ol li::before {
    font-size: var(--s6);
    border: 1px solid var(--c2);
    border-radius: var(--s6);
    content: counter(custom-counter);
    padding: 0 var(--s3);
    margin: 0 var(--s2) 0 0;
  }

  #signup .earn-phones {
    display: block;
    grid-column: 1/6;
    grid-row: 2/16;
    justify-self: center;
    width: 100%;
  }

  div.form-container h4.style1 {
    font-size: var(--s9);
  }

  #signup .form-container {
    grid-column: 6/10;
    grid-row: 14/16;
    order: 2;
  }

  #about_us {
    padding: 40px 0 20px 80px;
  }

  #about_us h2 {
    grid-column: 1/5;
    font-size: 48px;
    text-align: start;
    grid-row: 1/3;
  }

  #about_us button {
    grid-column: 1/3;
    grid-row: 4/5;
    font-size: var(--s6);
    padding: var(--s1) var(--s2);
    max-width: 200px;
  }

  #about_us img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    grid-column: 6/12;
    grid-row: 1/7;
  }

  #community article {
    grid-column: 1/8;
    grid-row: 1/12;
  }

  #community-cta .form-container {
    grid-column: 4/8;
  }

  #community img {
    display: block;
    grid-column: 8/10;
    grid-row: 3/8;
    width: 130%;
  }

  #privacy-policy h1 {
    font-size: 64px;
    grid-column: 2/7;
  }

  #privacy-policy h2 {
    font-size: 44px;
  }

  #privacy-policy h3 {
    font-size: 36px;
  }

  #faq .more-questions {
    grid-column: 3/9;
  }

  div .form-container2 input {
    width: 80%;
    padding: var(--s3) var(--s4);
  }

  div.top #logo {
    width: 10%;
  }

  /* bigger screens */
  nav,
  section,
  footer {
    max-width: 2000px;
  }

  nav {
    margin: var(--s1) var(--s4);
  }

  section,
  footer {
    margin: 0 auto;
  }
}

@media (min-width: 1346px) {
  #intro {
    padding: 40px 0;
  }

  /*#join input {
    font-size: 150px;
    line-height: 151px;
  }
  button.desktop-btn {
    font-size: var(--s9);
  }*/

  #links {
    padding: 150px 0;
  }

  #links img.qr-code {
    width: 100%;
  }

  #links h2 {
    font-size: var(--s10);
  }

  #earn-redeem div.slide-content-text {
    font-size: 30px;
  }
  #earn-redeem img {
    width: 60%;
  }

  #community ul.scroller-text {
    max-width: 75%;
    min-width: 70%;
  }

  #community img {
    width: 110%;
  }

  /*#earn-redeem {
    border: 60px solid var(--c1);
  }

  #earn-redeem div.slide-container {
    gap: 100px;
  }

  div.index-btns button.slide-btn {
    font-size: 38px;
  }

  #earn-redeem div.slide-content-container {
    padding: 5% 0;
  }

  div.slide-content {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-top: 50px;
  }

  div.slide-content-container h3 {
    font-size: 42px;
  }

  div.slide-content-text {
    font-size: var(--s10);
    margin-top: 55px;
  }*/
}
